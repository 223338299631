@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
body {
  font-family: 'Poppins' !important;
}
.container-slider {
  max-height: 700px;
  min-height: 700px;
  height: 700px;
  width: 100%;
  /* margin: 50px 0 50px 0; */
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .container-slider {
    max-height: 800px;
    min-height: 800px;
    height: 800px;
  }
}

@media screen and (max-width: 600px) {
  .container-slider {
    max-height: 1200px;
    min-height: 1200px;
    height: 1200px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.active-anim {
  opacity: 1;
  height: 100%;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prev {
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
  background: #dddddd;
}
.dot.active {
  background: #b1b1b1;
}

.wrapper {
  position: relative;
  border-radius: 50%;
  min-width: 130px;
  min-height: 130px;
}
img {
  opacity: 1;
}
.content {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 130px;
  height: 130px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay {
  opacity: 0;
  border-radius: '50%';
  transition: 1.3s linear;
}

.wrapper:hover img {
  opacity: 0.1;
  background-blend-mode: darken;
}

.wrapper:hover .overlay {
  opacity: 1;
  background-blend-mode: darken;
}

.container-slider {
  /* max-height: 750px;
  min-height: 750px;
  height: 750px;
  width: 100%; */
  /* margin: 50px 0 50px 0; */
  position: relative;
  /* overflow: hidden; */
}

@media screen and (max-width: 800px) {
  .container-slider {
    max-height: 800px;
    min-height: 800px;
    height: 800px;
  }
}

@media screen and (max-width: 600px) {
  .container-slider {
    /* max-height: 1200px;
    min-height: 1200px;
    height: 1200px; */
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.active-anim {
  opacity: 1;
  height: 100%;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prev {
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
  background: #dddddd;
}
.dot.active {
  background: #b1b1b1;
}

.container-slider {
  /* max-height: 750px;
  min-height: 750px;
  height: 750px;
  width: 100%; */
  /* margin: 50px 0 50px 0; */
  position: relative;
  /* overflow: hidden; */
}

@media screen and (max-width: 800px) {
  .container-slider {
    max-height: 800px;
    min-height: 800px;
    height: 800px;
  }
}

@media screen and (max-width: 600px) {
  .container-slider {
    /* max-height: 1200px;
    min-height: 1200px;
    height: 1200px; */
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.active-anim {
  opacity: 1;
  height: 100%;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prev {
  top: 50%;
  left: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
  background: #dddddd;
}
.dot.active {
  background: #b1b1b1;
}

.mobileNavBar {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 3 !important;
  /* opacity: 97%; */
}

.subNav {
  position: absolute;
  padding: 1%;
  margin: auto;
  background-color: white;
  border-radius: 5%;
}

.float {
  position: fixed;
  left: 15px;
  top: auto;
  z-index: 100;
}
