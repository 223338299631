.wrapper {
  position: relative;
  border-radius: 50%;
  min-width: 130px;
  min-height: 130px;
}
img {
  opacity: 1;
}
.content {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 130px;
  height: 130px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay {
  opacity: 0;
  border-radius: '50%';
  transition: 1.3s linear;
}

.wrapper:hover img {
  opacity: 0.1;
  background-blend-mode: darken;
}

.wrapper:hover .overlay {
  opacity: 1;
  background-blend-mode: darken;
}
