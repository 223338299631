.mobileNavBar {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 3 !important;
  /* opacity: 97%; */
}

.subNav {
  position: absolute;
  padding: 1%;
  margin: auto;
  background-color: white;
  border-radius: 5%;
}

.float {
  position: fixed;
  left: 15px;
  top: auto;
  z-index: 100;
}